.project-card{
    background-color: aqua;
    max-width: 280px;
    border-radius: 7px;
    width: 100%;
    aspect-ratio: 4 / 3;
    border-radius: 7px;
    flex: 1 1 calc(33.33% - 20px);
    min-width: 180px;
    align-content: center;
    text-align: center;
    height: calc(33.33% - 20px);

    }

    
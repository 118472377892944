.navigation-bar{

  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}
.navigation-bar-container {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 0px 8px 12px rgba(0, 63, 74, 0.05);
  display: flex;
  justify-content: space-between;
}

.pages-container{
  display: flex;
  gap: 30px;
  font-size: 18px;
}
.drawer-container{
}
.drawer-pages-container{
  text-align: center;

  gap: 10px;
  font-size: 18px;
  width: 100%; 
}
.clickable{
  cursor: pointer;

}
.control-opacity{
  background-color: rgba(255, 255, 255, 0.064);  
  backdrop-filter: blur(2px);
  height: 90%;
  width: 95%;
  z-index: 1000;
  position: fixed;
}

.main-container{
    display: flex;
    flex-wrap: wrap-reverse;
    align-content: space-evenly;
    height: 90vh;
    padding-left: 10%;
    padding-right: 10%;
    gap: 6%;
}
.left-container{
    max-width: 47%;
   
}
.right-container{
    max-width: 47%;
}
.main-container{
    padding-left: 10%;
    padding-right: 10%;
    
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: center; 
    align-items: stretch;

}
.project-header{
text-align: center;
font-size: 2.5rem;
margin-top: 20px;
margin-bottom: 20px;
}